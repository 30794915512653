import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/permission' //
import 'amfe-flexible' // 移动端适配
import './assets/styles/reset.scss'
import './assets/styles/border.scss'
// 在移动端H5开发过程中，关于点触可能会遇到手动点击与真正触发click事件会存在300ms的延迟，因此需要引入fastclick的库。
// import fastClick from 'fastclick'
import { pageConfig, sdkConfig } from './config'
// fastClick.attach(document.body)
import './permission' // 微信登录授权
// import './utils/viewSetting'
import './utils/track'
Vue.config.productionTip = false
if (['development', 'test'].indexOf(process.env.VUE_APP_MODE) > -1) {
  import('vconsole').then((res) => {
    // eslint-disable-next-line new-cap
    new res.default()
  })
}
const _vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
window.__vue = _vue
window.pageConfig = pageConfig
window.sdkConfig = sdkConfig
