import request from '@/utils/request'
// 获取直播url以及access_token
export const getHtLiveUrl = (params) => {
  return request(
    {
      url: '/live/huantuo/liveUrl',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取回放url以及access_token
export const getHtPlaybackUrl = (params) => {
  return request(
    {
      url: '/live/huantuo/playbackUrl',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 最新获取欢拓直播中token
export const getHtAccessToken = (params) => {
  return request(
    {
      url: '/live/na/joinLive',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 最新获取欢拓回放中token
export const getHtPlayBackAccessToken = (params) => {
  return request(
    {
      url: '/live/na/joinPlayback',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 最新欢拓助教端的token
export const getHtTeacherAccessToken = (params) => {
  return request(
    {
      url: '/live/teacherEnterLive',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取红包商品配置信息
export const getLiveConfig = (params) => {
  return request(
    {
      url: '/live/liveConfig',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取商品信息
export const getProductInfo = (params) => {
  return request(
    {
      url: '/live/productDetail',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 登录或者注册
export const saveUserInfo = (data) => {
  return request(
    {
      url: '/user/student/na/saveStudentInfo',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 获取userId
export const getStudentInfo = (params) => {
  return request(
    {
      url: '/user/student/info',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 上报用户进出直播间数据
export const reportUserData = (data) => {
  return request(
    {
      url: '/live/liveAccessRecord/reportUserData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 上报用户学习数据
export const reportStudyData = (data) => {
  return request(
    {
      url: '/live/studentStudyRecord/reportUserData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 获取自定义群发消息
export const initPushInfo = (params) => {
  return request(
    {
      url: `/business/liveMsgTemplate/getPushMsg`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 回放时候获取虚拟群发的消息列表
export const fakeSpeakList = (params) => {
  return request(
    {
      url: '/live/liveSpeakRecord/fakeSpeakList',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 创建订单
export const createOrder = (data) => {
  return request(
    {
      url: '/order/orderDetail/createOrder',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 1280升级1980创建订单升级
export const getOrderOrderUpgradeInfo = (params) => {
  return request(
    {
      url: '/order/orderInfo/orderUpgrade',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取尾款金额
export const getOrderDetailInfo = (params) => {
  return request(
    {
      url: '/order/orderDetail/info',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 微信支付
export const wechatPay = (data) => {
  return request(
    {
      url: '/order/wechat-pay/pay',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 支付宝支付
export const aliPay = (data) => {
  return request(
    {
      url: '/order/ali-pay/pay',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 通过商品id获取商品信息
export const getProductInfoById = (params) => {
  return request(
    {
      url: '/business/product/productDetail',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 1280升级1980获取商品及用户信息
export const getOrderUpgradeInfo = (params) => {
  return request(
    {
      url: '/order/orderInfo/getOrderUpgradeInfo',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 正价课获取班主任二维码
export const getClassqwCode = (params) => {
  return request(
    {
      url: '/business/formalStudentDistribution/qwCode',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取禁词列表
export const getDisabledMsgList = (params) => {
  return request(
    {
      url: '/live/listByProjectId',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 上报直播间点击埋点
export const reportClickData = (data) => {
  return request(
    {
      url: '/live/statistics/reportClickData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
export const reportKeepData = (data) => {
  return request(
    {
      url: '/live/statistics/reportKeepData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 获取腾讯IM签名
export const getTencentImAccountSign = (params) => {
  return request(
    {
      url: '/live/tencentImAccount/sign',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取腾讯IM签名
export const getImGroupId = (params) => {
  return request(
    {
      url: `/live/tencentImBindRoom/getTencentImGroupId/${params.roomId}`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取新版直播间推荐商品列表
export const getPopupTlProductList = (params) => {
  return request(
    {
      url: `/live/liveCoursePopupTl/popupTlProductList`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取自己聊天室的id
export const getSelfImGroupId = (params) => {
  return request(
    {
      url: `/live/tencentImBindRoom/getImGroupId/${params.roomId}`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取历史消息
export const getHistorySpeakRecord = (params) => {
  return request(
    {
      url: `/live/tencentImAccountGroupSpeakRecord/getHistorySpeakRecord/${params.groupId}`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}

// 上报直播间新版红包点击埋点
export const reportPopClickData = (data) => {
  return request(
    {
      url: '/live/statistics/reportPopClickData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 上报直播间新版弹窗时长埋点
export const reportPopKeepData = (data) => {
  return request(
    {
      url: '/live/statistics/reportPopKeepData',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}
// 获取用户所有当天所有直播课
export const getLiveCourseList = (params) => {
  return request(
    {
      url: '/business/live/studentTodayLiveList',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}

// 上报入群失败
export const recordJoinGroupErroyInfo = (data) => {
  return request(
    {
      url: process.env.VUE_APP_MODE === 'production' ? 'https://live-h5.jkqingman.com/live_h5_aduit_log/_doc' : 'https://test-live-h5.jkqianmei.com/live_h5_aduit_log/_doc',
      method: 'post',
      type: 'json', // json、 form
      data
    }
  )
}

// 获取协议额信息
export const getAgreementInfo = (params) => {
  return request(
    {
      url: '/user/agreement/getAgreementInfo',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}

// 签署协议
export const userConfirm = (params) => {
  return request(
    {
      url: '/user/userAgreementRecord/userConfirm',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 获取可用的支付渠道
export const getPayChannel = (params) => {
  return request(
    {
      url: '/order/pay/getPayChannel',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 直播间获取公告
export const getLiveNotice = (params) => {
  return request(
    {
      url: '/live/7y/getLiveNotice',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 横屏直播间获取推荐商品
export const getYuanliveProductList = (params) => {
  return request(
    {
      url: '/live/7y/liveProductList',
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}

// 获取分享链接
export const getPromotionShareUrl = (params) => {
  return request(
    {
      url: `/live/shareUrl/${params.projectId}`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 切换主备直播间获取最新token
export const getChangeLiveRoomToken = (params) => {
  return request(
    {
      url: `/live/getLiveToken`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
// 助教销售切换主备直播间获取最新token
export const getTeacherChangeLiveRoomToken = (params) => {
  return request(
    {
      url: `/live/getTeacherLiveToken`,
      method: 'get',
      type: 'json', // json、 form
      params
    }
  )
}
