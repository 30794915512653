import request from '@/utils/request'

export const getWechatRediredirectUrl = (data) => {
  return request(
    {
      url: '/open-api/wechat/service/getEmpowerUrl',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}

export const getWechatAccessToken = (data) => {
  return request(
    {
      url: '/open-api/wechat/service/getAccessToken',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}
// 获取稳定版接口凭证
export const getStableAccessToken = (data) => {
  return request(
    {
      url: '/open-api/wechat/service/getStableAccessToken',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}
// 获取用户UnionId
export const getUserUnionId = (data) => {
  return request(
    {
      url: '/open-api/wechat/service/getUserUnionId',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}

export const getUserInfoByAccessToken = (data) => {
  return request(
    {
      url: '/open-api/wechat/service/getUserInfo',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}

export const postGetWechatConfig = data => {
  return request({
    url: '/open-api/wechat/service/serviceJsTicketSign',
    method: 'post',
    type: 'json', // formDate类型数据值为 'form'
    // type: 'form',
    data
  })
}
// 判断用户是否是黑名单
export const getExistblackData = (data) => {
  return request(
    {
      url: '/business/blackData/exist/byStudentId',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}
// 获取用户小白营信息 二维码
export const getUserRookieInfo = (data) => {
  return request(
    {
      url: '/business/student/query/userRookieInfo',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}
//
export const getStudentUpdatePhone = (data) => {
  return request(
    {
      url: '/user/student/changePhone',
      method: 'get',
      type: 'json', // json、 form
      params: data
    }
  )
}

