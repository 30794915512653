<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>
<script>
(function() {
  // eslint-disable-next-line no-undef
  if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
    handleFontSize()
  } else {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    // 重写设置网页字体大小的事件
    // eslint-disable-next-line no-undef
    WeixinJSBridge.on('menu:setfont', function() {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    })
  }
})()
// const debounce = (fn, delay) => {
//   let timer = null
//   return function() {
//     const context = this
//     const args = arguments
//     clearTimeout(timer)
//     timer = setTimeout(function() {
//       fn.apply(context, args)
//     }, delay)
//   }
// }
// const _ResizeObserver = window.ResizeObserver
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback) {
//     callback = debounce(callback, 16)
//     super(callback)
//   }
// }
</script>

<style lang="scss">
body { /* IOS禁止微信调整字体大小 */
    -webkit-text-size-adjust: 100% !important;
    text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
}
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
