// import qs from 'qs'
import store from '@/store'
import { getUserAgent } from '@/utils/index.js'
import { getWechatRediredirectUrl, getWechatAccessToken, getUserInfoByAccessToken } from '@/api/common'
import { saveUserInfo, getStudentInfo } from '@/api/live'
import { postGetWechatConfig } from '@/api/common'

export const getParam = () => {
  // 将url后面的参数 ?name=msea&sex=1  >{name:"msea",sex:1}
  var search = {}
  if (location.search === '') return {}
  try {
    location.search
      .substr(1)
      .split('&')
      .forEach(function(item) {
        var s = item.split('=')
        search[s[0]] = s[1]
      })
  } catch (e) {
    // 抛出异常
    throw new Error(JSON.stringify(search))
  }
  return search
}
// 根据code获取微信access_token
export const getWXAccessToken = (type) => {
  return getWechatAccessToken({
    code: getParam().code,
    type: type || 2
  })
}
// 根据access_token获取用户信息
export const getInfoByAccessToken = (res) => {
  return getUserInfoByAccessToken({
    accessToken: res.access_token,
    openid: res.openid
  })
}
// 登录或者注册获取token
export const getToken = (res) => {
  return saveUserInfo({
    deviceOs: getUserAgent(),
    status: 1,
    unionid: res.unionid,
    avatarAddress: res.headimgurl || null,
    studentName: res.nickname || null,
    openid: res.openid,
    promotionId: 4231
  })
}

export const getCode = async(type, callBack) => {
  if (type === 'authorization') {
    // 微信授权获取code
    if (getParam().code) {
      // 获取access_token
      const resultByCode = await getWXAccessToken()
      console.log(resultByCode)
      // 获取userInfo
      const resultByAccessToken = await getInfoByAccessToken(resultByCode)
      localStorage.setItem('userInfo', JSON.stringify(resultByAccessToken))
      store.commit('setUserInfo', resultByAccessToken)

      // 保存学员信息并且获取登录token（登录/注册）
      const resultByOpenId = await getToken(resultByAccessToken)
      localStorage.setItem('token', resultByOpenId)
      store.commit('setToken', resultByOpenId)
      // 获取学员信息
      const studentInfo = await getStudentInfo({})
      localStorage.setItem('studentInfo', JSON.stringify(studentInfo))
      store.commit('setStudentInfo', studentInfo)
      history.replaceState(null, '', window.location.pathname + window.location.hash)
      // window.location.href = window.location.origin + window.location.pathname + window.location.hash
      console.log('跳转1111')
      callBack && callBack()
    } else {
      // 获取微信用户授权，微信跳转后回调到url并且拼接code参数
      getWechatRediredirectUrl({ type: 2 }).then(res => {
        // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxfaca70e5fc1ae0a4&redirect_uri=http%3A%2F%2Ftest-h5.jkqingman.com%2F----&response_type=code&scope=snsapi_userinfo&forcePopup=false#wechat_redirect"
        //  返回的url中，会有四个----，需要替换为前端回调地址
        const url = res.replace('%2F----', encodeURIComponent(location.pathname + location.search))
        location.href = url
      })
    }
  }
}

// wxConfigData
const wx = window.wx
const jssdkInit = (jsApiList, callBack) => {
  const shareUrl = process.env.VUE_APP_MODE === 'development' ? 'https://live.xinglzh.com/' : location.href
  postGetWechatConfig({
    url: shareUrl,
    type: 1
  }).then(res => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.appId, // 必填，公众号的唯一标识
      timestamp: res.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.nonceStr, // 必填，生成签名的随机串
      signature: res.sign, // 必填，签名
      jsApiList // 必填，需要使用的JS接口列表
    })
  })
  wx.ready(function(res) {
    callBack()
  })
  wx.error(function(res) {
    console.log(res, '微信错误回调信息')
  })
}
// 分享
const share = ({ title, desc, imgUrl, link }) => {
  // 分享朋友圈
  wx.updateAppMessageShareData({
    title: title || '', // 分享标题
    desc: desc || '', // 分享描述
    link: link || location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: imgUrl || '', // 分享图标
    success: () => {
      // 设置成功
      console.log('分享成功')
    }
  })
  wx.onMenuShareAppMessage({
    title: title || '', // 分享标题
    desc: desc || '', // 分享描述
    type: 'link',
    link: link || location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: imgUrl || '', // 分享图标
    success: () => {
      // 设置成功
    },
    fail: (res) => {
      // alert(JSON.stringify(res));
    }
  })
  // eslint-disable-next-line no-undef
  wx.updateTimelineShareData({
    title: title || '', // 分享标题
    link: link || location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: imgUrl || '', // 分享图标
    success: () => {
      console.log('分享成功')
      // 用户点击了分享后执行的回调函数
    }
  })
  wx.onMenuShareTimeline({
    title: title || '', // 分享标题
    type: 'link',
    link: link || location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: '', // 分享图标
    success: () => {
      // 设置成功
    },
    fail: (res) => {
      // alert(JSON.stringify(res));
    }
  })
}

export const wxJssdk = async({ type, options }) => {
  if (type === 'share') {
    jssdkInit(
      [
        'updateAppMessageShareData', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        'updateTimelineShareData',
        'onMenuShareAppMessage',
        'onMenuShareTimeline'
      ],
      () => {
        share(options)
      }
    )
  }
}
