import { getTencentImAccountSign, getImGroupId } from '@/api/live'
import route from '@/router'

export const tencentIm = () => {
  let params = {}
  if (route.app._route.query.platformUserType === 'assistant') {
    params = {
      type: `1${route.app._route.query.tencentIdType}`
    }
  }
  return getTencentImAccountSign(params).then(res => {
    // sdkAppId 聊天室应用ID
    // sign用户签名
    // status 状态 1 正常 0 禁言
    // userId 用户ID
    return res
  }).catch((e) => {
    console.log(e)
  })
}
// 获取群ID
export const getTencentImGroupId = (roomId) => {
  // 获取群id
  return getImGroupId({
    roomId: roomId
  }).then(res => {
    // window.access_token = res.access_token
    return res
  }).catch((e) => {
    console.log(e)
  })
}

export const isWeChat = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  // eslint-disable-next-line eqeqeq
  return ua.match(/MicroMessenger/i) == 'micromessenger'
}
// 微信环境判断
export const getIsWxClient = () => {
  var ua = navigator.userAgent.toLowerCase()
  // eslint-disable-next-line eqeqeq
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  }
  return false
}
export const getParam = () => {
  // 将url后面的参数 ?name=msea&sex=1  >{name:"msea",sex:1}
  var search = {}
  if (location.search === '') return {}
  try {
    location.search
      .substr(1)
      .split('&')
      .forEach(function(item) {
        var s = item.split('=')
        search[s[0]] = s[1]
      })
  } catch (e) {
    // 抛出异常
    throw new Error(JSON.stringify(search))
  }
  return search
}
export const getUserAgent = () => {
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // 判断是否是 android终端
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 判断是否是 iOS终端
  if (isAndroid) {
    return 'Android'
  } else if (isIOS) {
    return 'IOS'
  } else {
    return 'PC'
  }
}

// 上报点击埋点
import { reportClickData } from '@/api/live'

export const postClickTrack = (clickParamsData) => {
  if (route.app._route.query.platformUserType === 'assistant') {
    return false
  }
  // console.log('上报埋点事件')
  // console.log(clickParamsData)
  reportClickData(clickParamsData).then(res => {
  }).catch((e) => {
    console.log(e)
  })
}

export const second2HMS = (d, str) => {
  d = parseInt(d, 10)
  var h = Math.floor(d / 3600)
  var m = Math.floor(d % 3600 / 60)
  var s = Math.floor(d % 3600 % 60)
  var times = ''
  function format(num) {
    var val = 0
    if (num > 0) {
      if (num >= 10) {
        val = num
      } else {
        val = '0' + num
      }
    } else {
      val = '00'
    }
    return val
  }
  var hr = format(h)
  var min = format(m)
  var sec = format(s)

  if (str) {
    if (str === 'total') {
      if (hr > 0) {
        times = hr + ':' + min + ':' + sec
      } else {
        times = min + ':' + sec
      }
    } else if (str === 'cur') {
      if (hr > 0) {
        times = hr + ':' + min + ':' + sec
      } else {
        times = '00:' + min + ':' + sec
      }
    }
  }
  return times
}
// 判断项目类型// 1：营养，2：养生
export const getProjectType = (projectId) => {
  if (process.env.VUE_APP_MODE === 'production') {
    if ([2, 8, 9, 10].includes(projectId)) {
      // 营养项目
      return {
        type: 1
      }
    } else if ([1, 3, 4, 5, 7, 11, 12].includes(projectId)) {
      // 养生
      return {
        type: 2
      }
    }
  } else {
    if ([7, 9, 10].includes(projectId)) {
      // 营养项目
      return {
        type: 1
      }
    } else if ([1, 2, 3, 4, 8, 11, 12].includes(projectId)) {
      // 养生
      return {
        type: 2
      }
    }
  }
}
