import Vue from 'vue'
import { reportClickData } from '@/api/live'

// 自定义埋点指令
Vue.directive('track', {
  // 钩子函数，只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置
  /*
     * el:指令所绑定的元素，可以用来直接操作 DOM
     * binding：一个对象，包含以下 property:
     *   name：指令名，不包括 v- 前缀。
     *   value：指令的绑定值，例如：v-my-directive="1 + 1" 中，绑定值为 2。
     *   expression：字符串形式的指令表达式。例如 v-my-directive="1 + 1" 中，表达式为 "1 + 1"。
     *   arg：传给指令的参数，可选。例如 v-my-directive:foo 中，参数为 "foo"。
     *   modifiers：一个包含修饰符的对象。例如：v-my-directive.foo.bar 中，修饰符对象为 { foo: true, bar: true }。
     * vnode：Vue 编译生成的虚拟节点
     */
  bind: (el, binding, vnode) => {
    if (binding.value) {
      // 这里参数是根据自己业务可以自己定义需要什么参数就自己定义
      const clickParamsData = {
        // 操作类型(1:报名通知,2:大红包,3:小红包) int
        operateType: binding.value.operateType,
        // 子类型( 1:开红包, 2:关闭红包, 3:残忍拒绝大红包, 4:立即领取大红包, 5:关闭商品详情, 6:商品详情立即购买, 7:残忍拒绝, 8:立即购买 9:微信支付 10:支付宝支付 11:立即支付
        subType: binding.value.subType,
        // 事件点击时间
        time: binding.value.time,
        // 用户编码(类型=0时是studentId)
        userCode: binding.value.userCode,
        // 用户类型(0:登录用户,1:未登录用户)
        userType: binding.value.userType,
        // 观看类型(0:直播,1:回放)
        watchType: binding.value.watchType
      }
      const viewParamsData = {
        // 停留时间
        keepTime: binding.value.keepTime,
        // 操作类型(1:报名通知,2:大红包,3:小红包)
        operateType: binding.value.operateType,
        // 子类型( 1:开红包页面, 2:关闭红包页面, 3:商品详情页面, 4:商品详情关闭页面, 5:支付方式选择页面,
        subType: binding.value.subType,
        // 用户编码(类型=0时是studentId)
        userCode: binding.value.userCode,
        // 用户类型(0:登录用户,1:未登录用户)
        userType: binding.value.userType,
        // 观看类型(0:直播,1:回放)
        watchType: binding.value.watchType
      }
      // 如果是浏览类型，直接保存
      if (binding.value.triggerType === 'browse') {
        console.log(viewParamsData, '浏览了')
        // 调用后台接口保存数据params为入参
      } else if (binding.value.triggerType === 'click') {
        // 如果是click类型，监听click事件
        el.addEventListener('click', (event) => {
          // 调用后台接口保存数据params为入参
        //   console.log(event, clickParamsData, '点击了')
          reportClickData(clickParamsData).then(res => {
          }).catch((e) => {
            console.log(e)
          })
        }, false)
      }
    }
  }
})
