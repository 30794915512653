import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 微信用户信息
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    // 学员信息
    studentInfo: localStorage.getItem('studentInfo') ? JSON.parse(localStorage.getItem('studentInfo')) : {},
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    liveCourseInfo: {
      // 课程id
      liveCourseId: null,
      // 项目id
      projectId: null,
      // 欢拓id
      liveAccessToken: '',
      // 当前直播间状态
      liveRoomStatus: null,
      // 1: 新样式 2：旧样式
      redEnvelopePopType: null,
      // 营期id
      periodId: null,
      // 班级id
      rookieGradeId: null,
      // 课节
      classSection: null
    },
    // 购物车模式，是否正在售卖商品状态
    salesStatus: false
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = Object.assign(state.userInfo, {
        ...data
      })
    },
    setStudentInfo(state, data) {
      state.studentInfo = Object.assign(state.studentInfo, {
        ...data
      })
    },
    setToken: (state, data) => {
      state.token = data
    },
    setLiveCourseInfo(state, data) {
      state.liveCourseInfo = Object.assign(state.liveCourseInfo, {
        ...data
      })
    },
    setSalesStatus: (state, data) => {
      state.salesStatus = data
    }
  },
  actions: {
  },
  modules: {
  }
})
