import router from './router'
import { isWeChat } from '@/utils/index'
import { getCode, getParam } from '@/utils/wx'
import { Toast } from 'vant'

// const whiteList = ['/LiveResult'] // 设置没有token可以进入页面
// '/Live','/CommunityPay'
const get_wx_code = ['/Live', '/CommunityPay', '/TransferLive', '/TransverseLive', '/PlayBackIndex', '/PlayBackList', '/CommunityUpgradePay', '/DiversionUser']
router.beforeEach(async(to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  }
  if (to.query.platformUserType === 'assistant') {
    // 助教端
    localStorage.setItem('token', to.query.token)
    next()
  } else {
    // 学员端
    if (isWeChat()) {
      if (get_wx_code.includes(to.path)) {
        // localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOb25jZSI6ImM4MThhMTc5LTE0MmEtNGMzMS05NGVkLTllN2Q3Yjk4OTUzMiIsIlVzZXJJZCI6IjIiLCJTeXNUeXBlIjoiMCJ9.C_ZoCiGZ-QS86J29JsJkD9PEgh6YVMaxE1IfHGiDn1Q')
        // localStorage.setItem('studentInfo', JSON.stringify({
        //   'id': 2,
        //   'studentName': '刘洋',
        //   'avatarAddress': 'https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eoeUITicZDzSic7rLXDdddq6Q6eZyO9ORgQq8yb3zYgpko8J18gxbU7QE7ic9Lmz0eZgYiaq6OPAsricrg/132',
        //   'unionid': 'osSRW6Wj-aL95IV6ykfwGGMWO7cY',
        //   'openid': 'oiMEr6tG27V7mol1yDu-FyavHbvU',
        //   'phone': null,
        //   'deviceCode': null,
        //   'deviceOs': 'IOS',
        //   'status': null,
        //   'createTime': '2024-08-15 18:47:04',
        //   'updateTime': '2024-08-16 11:16:00',
        //   'loginNonce': '8324e321-e50f-4416-ae62-f97c6ada4d12'
        // }))
        const studentInfo = localStorage.getItem('studentInfo')
        if (localStorage.getItem('token') && studentInfo && JSON.parse(studentInfo).id) {
          next()
        } else {
          next({
            path: '/liveLogin',
            query: {
              path: to.path,
              query: JSON.stringify(to.query)
            }
          })
        }
      } else if (to.path === '/liveLogin') {
        if (getParam().code) {
          getCode('authorization', () => {
            next({
              path: to.query.path,
              query: JSON.parse(to.query.query)
            })
          })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      Toast('请在微信中打开此链接')
      // next()
    }
  }
})
