import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Live',
    name: 'Live',
    component: () => import('@/views/Live/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/LiveResult',
    name: 'LiveResult',
    component: () => import('@/views/LiveResult/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/CommunityPay',
    name: 'CommunityPay',
    component: () => import('@/views/CommunityPay/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/CommunityUpgradePay',
    name: 'CommunityUpgradePay',
    component: () => import('@/views/CommunityUpgradePay/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/InsideCommunityPay',
    name: 'InsideCommunityPay',
    component: () => import('@/views/InsideCommunityPay/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/Agreement',
    name: 'Agreement',
    component: () => import('@/views/Agreement/index.vue'),
    meta: {
      title: '鹊川学堂',
      auth: true // 如果此路由需要微信授权请设置为true,默认为false
      // keepAlive: true  //是否设置为缓存数据页面
    }
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    component: () => import('@/views/AboutView.vue'),
    meta: { title: '升级公告' }
  },
  {
    path: '/poptest',
    name: 'poptest',
    component: () => import('@/views/Live/LiveTestPop.vue'),
    meta: { title: '弹出组件' }
  },
  {
    path: '/liveLogin',
    name: 'liveLogin',
    component: () => import('@/views/liveLogin/index.vue'),
    meta: { title: '鹊川学堂' }
  },
  {
    path: '/TransferLive',
    name: 'TransferLive',
    component: () => import('@/views/TransferLive/index.vue'),
    meta: { title: '鹊川学堂' }
  },
  {
    path: '/TransverseLive',
    name: 'TransverseLive',
    component: () => import('@/views/TransverseLive/index.vue'),
    meta: { title: '鹊川学堂' }
  },
  {
    path: '/PlayBackIndex',
    name: 'PlayBackIndex',
    component: () => import('@/views/PlayBack/index.vue'),
    meta: { title: '鹊川学堂' }
  },
  {
    path: '/PlayBackList',
    name: 'PlayBackList',
    component: () => import('@/views/PlayBack/list.vue'),
    meta: { title: '鹊川学堂' }
  },
  {
    path: '/DiversionUser',
    name: 'DiversionUser',
    component: () => import('@/views/DiversionUser/index.vue'),
    meta: { title: '' }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
