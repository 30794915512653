// 直播配置
export const pageConfig = {
  // 全局功能设置
  'global': {
    // 基础设置
    'setting': {
      'pageViewMode': '0' // 页面设置 半屏模式==>0;全屏模式==>1;课件模式==>2
    },
    // 功能开关
    'switch': {
      // 关注
      'focus': {
        'enable': 0, // 0==>关闭 1==>打开
        'name': '关注',
        'data': {
          'popUp': 0, // 进入直播间弹出
          'url': '' // 链接
        }
      },
      // 在线人数
      'number': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '人数',
        // 累加模式
        'accumulation': {
          'enable': 0 // 0==>关闭 1==>打开
        },
        // 人气模式
        'popularity': {
          'enable': 0, // 0==>关闭 1==>打开
          'num': 0 // 人气参数
        }
      },
      // 邀请榜单
      'invitationList': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '邀请榜单'
      },
      // 直播倒计时
      'countDown': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '直播倒计时'
      },
      // 商城入口
      'store': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '商城入口',
        'type': '1', // 商城类型 1==>列表 2==>链接 3==>二维码
        'data': {
          'url': '', // 链接地址
          'qrcode': '' // 二维码地址
        }
      },
      // 打赏主播
      'reward': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '打赏主播',
        'type': '1',
        'data': []
      },
      // 邀请观看
      'inviteRanking': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '邀请观看'
      },
      // 红包
      'redPack': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '红包'
      },
      // 点赞
      'like': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '点赞'
      },
      // 更多入口
      'more': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '更多入口',
        'data': {
          // 红包记录
          'redPackRecord': 0,
          // 体现
          'withdraw': 0,
          // 商城入口
          'store': {
            'enable': 0, // 0==>关闭 1==>打开
            'url': ''
          },
          // 个人中心
          'info': 0,
          // 线路中心
          'liveLine': 0,
          // 举报
          'report': 0
        }
      },
      // 菜单入口
      'menu': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '菜单入口'
      },
      // 入场提示
      'entranceTips': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '入场提示'
      },
      // 广告条
      'banner': {
        'enable': '0', // 0==>关闭 1==>打开
        'name': '广告条',
        'data': []
      },
      // 直播引导图
      'intro': {
        'name': '直播引导图',
        'url': '', // pc链接
        'H5Url': '' // h5链接
      },
      // 客服私聊
      'service': {
        'enable': 0// 0==>关闭 1==>打开
      },
      // 直播背景图
      'background': {
        'enable': '0', // 0==>关闭 1==>打开
        'url': ''
      },
      // 提问
      'question': {
        'enable': '0'// 0==>关闭 1==>打开
      },
      // 课件背景图
      'pptbackground': {
        'enable': 0, // 0==>关闭 1==>打开
        'url': ''
      },
      // 暖场视频
      'warmup': {
        'enable': '0', // 0==>关闭 1==>打开
        'fileName': '',
        'videoId': ''
      },
      // 浮动广告
      'ad': {
        'enable': 0
      },
      // 消息时间
      'chatTime': {
        'enable': 0
      }
    },
    'title': [],
    // 分享设置
    'share': {
      'title': {
        'type': '1',
        'value': ''
      },
      'description': {
        'value': '这个直播不错哟，分享给你，我们一起看看吧'
      },
      'img': {
        'value': ''
      },
      'qrcode': {
        'value': ''
      }
    }
  },
  // 自定义菜单内容 type: chat(聊天) / invite(邀请榜单) / editor(富文本) / courseware(课件)
  'content': {
    'tabsData': [
      {
        'title': '聊天',
        'name': '',
        'type': 'chat',
        'content': '' // 富文本内容
      }
    ]
  },
  //   商品列表
  //   格式:
  //   {
  //   id: 0  商品ID
  //   img: ""  商品图片
  //   link_text: ""  跳转按钮文本内容
  //   name: ""  商品名称
  //   originalPrice: ""  市场售价
  //   pay: ""   购买模式 1==>链接购买 2==>二维码购买
  //   price: "" 商品售价
  //   putaway: "" 上架状态 1==>上架 0==>下架
  //   qrcode: ""  二维码图片地址
  //   tab: ""  标签 0==>无 1==>特价 2==>限时 3==>新品 4==>钜惠 5==>秒杀
  //   url: "" 商品跳转链接地址
  // }
  'goods': [],
  // 推荐弹窗列表
  // 格式:
  // {
  // duration: "" 展示时间
  // img: ""  弹窗图片
  // pushTime: "" 推送时间
  // status: 0 推送状态 0==>未推送 1==>已推送
  // title: "" 推送标题
  // type: "1" 推送类型 1==>手动推送 2==>进入直播间推送
  // url: "123"
  // }
  'popUp': [],
  // 浮动广告列表
  // 参数格式：{
  //   "width": 100, 图片宽度
  //   "height": 100, 图片高度
  //   "img": "", 图片地址
  //   "name": "", 图片名称
  //   "time": "", 上传时间
  //   "position": { "top": 0, "left": 100 }, 定位坐标（单位:%，不能超过100）
  //   "scale": 30, 缩放比例 (单位：%，相对于屏幕的宽度)
  //   "link": "" 跳转链接地址
  // }
  'adlist': []
}
// sdk配置 文档地址:http://open.talk-fun.com/docs/js/sdk.js.getstart.html
export const sdkConfig = {
  config: {
    techOrder: 'FLV', // 优选(FLV, FLASH, HLS)播放
    controls: false // 视频播放器导航（全屏，刷新）
  },
  // 原生视频配置属性
  video: {
    autoplay: true, // 自动播放（移动端由于浏览器安全限制禁止自动播放，如有疑问请先查询相关资料）
    controls: false, // 视频控件
    preload: 'auto', // 预加载
    poster: true // 视频海报图
  }
}

